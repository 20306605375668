
// Add authorization token to headers
import { omit } from 'lodash'

async function request(config: unknown) {
  let token: unknown | null = null;

  try {
    token = localStorage.getItem('TOKEN');
    console.log(token);
  } catch {}

  const url = config.url as string;
  const pattern = /^https:\/\/[^\/]*blob\.core\.windows\.net/;

  if (pattern.test(url)) {
    config.headers = omit(config.headers, ['Authorization']);
    return config
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

// Intercept 401s and redirect you to login
function responseError(res: unknown) {
  console.log('res: ', res);

  throw res;
  // throw new Error(res);
}

export const interceptor = () => ({ request, responseError });
