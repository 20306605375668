import angular from 'angular';
import { State } from 'angular-store';

import { Controller, Inject } from '@decorators/ngCtrl';
import Modal from '@mixins/modal.mixin';

@Controller
class ChangePasswordModalController extends Modal {
  user: number;

  @Inject $scope;
  @Inject $store;
  @Inject $auth;
  @Inject $http;
  @Inject Notification;
  @Inject notify;

  @State me;

  async $setup() {
    this.parent = this.$scope.$parent;

    this.newPassword = '';
    this.oldPassword = '';
    this.confirmPassword = '';

    // this.user = this.me.id;
    this.user = await this.$auth.currentAuthenticatedUser();
  }

  checkPassword(passwordField) {
    var pass = passwordField.$modelValue;
    if (pass?.length < 8) {
      passwordField.$valid = false;
    } else {
      var hasUpperCase = /[A-Z]/.test(pass);
      var hasLowerCase = /[a-z]/.test(pass);
      var hasNumbers = /\d/.test(pass);
      var hasNonalphas = /\W/.test(pass);
      if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
        passwordField.$valid = false;
      } else {
        passwordField.$valid = true;
      }
    }
  }

  confirmPW(pw, passwordField) {
    if (pw.$modelValue !== passwordField.$modelValue) {
      passwordField.$valid = false;
    } else {
      passwordField.$valid = true;
    }
  }

  async changePW1(form) {
    if (!form.$valid) {
      return this.notify.error('Please fill out all parts of the form.');
    }

    let res: unknonw = null;
    let error: unknonw = null;

    this.processing = true;

    try {
      res = await this.$auth.changePassword(
        this.oldPassword,
        this.newPassword
      );
    } catch (_) {
      //
      this.notify.display(res, 'error');
    }

    this.processing = false;


    this.notify.display('Password successfully changed', 'success');

    this.$close();
  }
}

export default angular
  .module('app.changePasswordModal', [])
  .directive('changePasswordModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./change-password.html'),
    controller: ChangePasswordModalController,
    controllerAs: 'vm'
  })).name;
