import download from 'downloadjs';

import { State, Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

import { find } from 'lodash';

@Controller
class DashboardAggregateReports {
  tableProps: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  get tableItems() {
    return this.$store.state.aggregateReports.items;
  }

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $modals;
  @Inject $auth;
  @Inject $store;
  @Inject $api;
  @Inject $acl;
  @Inject $http;
  @Inject notify;

  @State me;
  @State permissions;
  @Getter activeInstId;
  @Getter isAdmin;

  async $onInit() {
    await this.$store.dispatch('aggregateReports/list', {
      instId: this.activeInstId
    });

    this.tableProps = [
      {
        label: 'Tool',
        value: 'tool.name'
      },
      {
        label: 'Start Date',
        value: 'startDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'End Date',
        value: 'endDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Created',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Status',
        value: (row) => {
          let output = '';
          let scanTag = null;

          if (row.tags && row.tags?.length) {
            // new scan tag
            scanTag = find(row.tags, { Key: 'scan-status' });

            // fallback to old scan tag
            if (!scanTag) scanTag = find(row.tags, { Key: 'av-status' });
          }

          if (row.status === 'QUEUED') {
            output = `<label class="status-label in-progress">In Progress</label>`;
          } else if (row.status === 'ERROR') {
            output = row.error
              ? `<label class="status-label not-started">${row.error}</label>`
              : `<label class="status-label not-started">Failed</label>`;
          } else if (
            row.status === 'SCANNING' ||
            (scanTag && scanTag.Value !== 'CLEAN')
          ) {
            output = `<label class="status-label in-progress">Scanning</label>`;
          } else if (row.status === 'COMPLETED') {
            output = `<label class="status-label completed">Completed</label>`;
          }

          return output;
        }
      }
    ];

    if (this.isAdmin) {
      this.tableProps.unshift({
        label: 'Requesting Institution',
        value: 'account.name'
      });
    }

    this.tableActions = [
      {
        label: 'Download',
        icon: 'cloud-download-alt',
        actions: async (val) => {
          // Download the CSV...
          this.notify.success('Downloading Report');
          const mimeType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          var url;

          if (
            this.$acl(
              'GM:DownloadAggregateReportCSV',
              this.permissions.profile
            ) &&
            val?.account?.id == 'gifrgears'
          ) {
            url = `/api/gears-manager/aggreport-requests/${val.id}/download`;
          } else if (
            this.$acl('IM:DownloadAggregateReportCSV', this.permissions.profile)
          ) {
            const instId = this.activeInstId
              ? this.activeInstId
              : val.account?.id;
            url = `/api/institution-manager/${instId}/aggreport-requests/${val.id}/download`;
          }

          let res;
          try {
            const existingHeaders = this.$http.defaults.headers.common;

            const newHeaders = {
              Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            };

            const headers = Object.assign({}, existingHeaders, newHeaders);

            const config = {
              responseType: 'arraybuffer',
              headers: headers
            };
            res = await this.$http.get(url, config);
            var blob = new Blob([res.data], { type: mimeType });
            
            download(blob, "report.xls", mimeType);
          } catch (err) {
            this.notify.error(err);
            return;
          }

          if (!res) {
            this.notify.error('Error - Retrieving Report');
            return;
          }

          // Newly expected signed url location is: res.data.url
          // const downloadUrl = res.data?.url;

          // if (!downloadUrl) {
          //   this.notify.error('No Download URL found.');
          //   return;
          // }

          // const config = {
          //   responseType: 'arraybuffer',
          //   headers: {
          //     Accept:
          //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          //   }
          // };

          // try {
          //   res = await this.$http.get(downloadUrl, config);
          // } catch (err) {
          //   this.notify.error(err);
          //   return;
          // }

          // if (!res) {
          //   this.notify.error('Error - Downloading Report');
          //   return;
          // }

          // var blob = new Blob([res.data], { type: mimeType });
          // download(blob, 'report.xls', mimeType);
        },
        disabled: ({ status, tags }) => {
          if (tags && tags.length) {
            // new scan tag
            let tag = find(tags, { Key: 'scan-status' });

            // fallback to old scan tag
            if (!tag) tag = find(tags, { Key: 'av-status' });

            return tag.Value !== 'CLEAN';
          }

          if (status !== 'COMPLETED') return true;
        }
      }
    ];
  }
}

export default DashboardAggregateReports;
