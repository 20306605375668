import { Component, Ng, State, Mutation, Action } from '@angular';

import { RootState, RootModules } from '@store/state';
import { RootMutations } from '@store/mutations';
import { RootActions } from '@store/actions';

import template from './app-base.html';

/**
 * ...
 *
 * @param name ...
 * @param value ...
 * @return ...
 */
function setRootAttribute(name: string, value: string) {
  document.documentElement.setAttribute(`data-${name}`, value);
}

@Component({ name: 'appBase', template })
class AppBaseComponent extends Ng {
  idolTracking = false;
  idolTime = 0;

  @State readonly me!: RootModules['me'];
  @State readonly authenticating!: RootState['authenticating'];
  @State readonly loadingData!: RootState['loadingData'];
  @State readonly loadingProgress!: RootState['loadingProgress'];
  @State readonly onDashboard!: RootState['onDashboard'];
  @State readonly onToolCreator!: RootState['onToolCreator'];
  @State readonly theme!: RootState['theme'];
  @Mutation readonly BROWSER_FLAGS!: RootMutations['BROWSER_FLAGS'];
  @Mutation readonly SET_THEME!: RootMutations['SET_THEME'];
  @Action readonly fetchAppVersion!: RootActions['clearStates'];
  @Action('evaluationRequests/getAll') readonly getAllEvlReqs!: any;

  get isLoginAuthEntra() {
    return this.$auth.isLoginAuthEntra;
  }

  get appLoading() {
    return this.authenticating || this.loadingData || this.$auth.isLoginAuthEntra;
  }

  get progressBar() {
    return 100 * (1 - this.loadingProgress);
  }

  $onInit() {
    this.BROWSER_FLAGS();

    // Fetch and store current version of the web app.
    this.fetchAppVersion();

    void this.$auth.loggedInAsync.then((loggedIn) => {
      if (loggedIn) this.idolTracker.start();
    });

    setRootAttribute('theme', this.theme);

    this.$on('loggedIn', this.onLoggedIn);
    this.$on('transferApproved', this.onTransferApproved);
    this.$on('transferDenied', this.onTransferDenied);
    this.$watch('theme', this.onThemeChanged);
  }

  /**
   * ...
   */
  private onLoggedIn = () => {
    void this.$state.go('dashboardHome');

    this.idolTracker.start();
  };

  /**
   * ...
   */
  private onTransferApproved = () => {
    this.utils.notify(
      'success',
      'Institution Super Admin Transfer Successfully Approved'
    );

    void this.$modals.settings.reloadPage(
      'To assume the new role you must log out and log back in? Would you like to do that now?',
      'LOGOUT'
    );
  };

  /**
   * ...
   */
  private onTransferDenied = () => {
    this.utils.notify(
      'success',
      'Institution Super Admin Transfer Successfully Denied'
    );
  };

  /**
   * ...
   */
  private onThemeChanged = (theme: RootState['theme']) => {
    setRootAttribute('theme', theme);
  };
}

export default AppBaseComponent.$module;
