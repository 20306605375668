import angular from 'angular';
import * as _ from 'lodash';
import { format } from 'date-fns';
import download from 'downloadjs';

export function ReportUtilsService(
  $http,
  $location,
  $api,
  $state,
  $store,
  $generatePdf,
  $auth,
  getItems,
  $modals,
  notify,
  Notification,
  errorHandler,
  loadTool,
  Upload
) {
  'ngInject';

  // console.log('Using report-utils v1');

  const $loading = false;
  let $emailingReport = false;
  let $generatingPDF = false;

  let $evl;
  let $genCasePlanEvalId;

  let $client;

  const _reports = $store.state.reports.items;
  const _users = $store.state.users.items;
  const _clients = $store.state.clients.items;
  const _tools = $store.state.tools.items;

  const loadReports = async function loadReports(evlId) {
    if (!$client) $client = $store.state.clients.focus;
    if ($client.id !== $store.state?.clients?.focus?.id)
      $client = $store.state.clients.focus;
    $store.commit('evaluations/setFocus', evlId);
    $store.dispatch('reports/getForEvaluation', { id: evlId, client: $client });

    // if (!!res.data.error) {
    //   throw res.data.error;
    // }

    _reports.forEach((report) => {
      _tools.forEach((tool) => {
        if (report.toolId === tool.id) {
          report.toolName = tool.name;
        }
      });
    });
  };

  const reportUtils = {
    get isLoading() {
      return $loading;
    },
    get evaluation() {
      return $evl;
    },
    set evaluation(val) {
      $evl = val;
    },
    get evalToGenCasePlan() {
      // variable tracked between pages if we want to generate a case plan off of the eval on the client page
      return $genCasePlanEvalId;
    },
    set evalToGenCasePlan(val) {
      $genCasePlanEvalId = val;
    },
    // get evlForReport() {
    //   return $store.state.evaluations.focus;
    // },
    // set evlForReport(val) {
    //   $store.commit(
    //     'evaluations/setFocus',
    //     typeof val === 'number' ? val : val.id
    //   );
    // },
    async generateProfileReport(tool, evaluation, client) {
      $generatingPDF = true;
      let pdf;

      // console.log(tool, evaluation, client);
      if (!client.gender) client.gender = null;
      switch (tool.id) {
        case 120:
          // YLS/CMI
          const ylsProfileReportChoices =
            await $modals.evaluation.ylsProfileReportChoices();
          if (!ylsProfileReportChoices) return;
          pdf = await $generatePdf.generateYLSProfileReport(
            client,
            evaluation,
            tool,
            ylsProfileReportChoices
          );
          break;
        case 124:
          // LS/CMI
          const lscmiProfileReportChoices =
            await $modals.evaluation.lscmiProfileReportChoices();
          if (!lscmiProfileReportChoices) return;
          pdf = await $generatePdf.generateLSCMIProfileReport(
            client,
            evaluation,
            tool,
            lscmiProfileReportChoices
          );
          break;
        case 105:
          // YLS/CMI:SRV
          pdf = await $generatePdf.generateYLSSRVProfileReport(
            client,
            evaluation,
            tool
          );
          break;
        case 4:
          // LSI-R:SV
          pdf = await $generatePdf.generateLSIRSVProfileReport(
            client,
            evaluation,
            tool
          );
          break;
        case 156:
          // Hare PCL-R
          const harePCLRProfileReportChoices =
            await $modals.evaluation.harePCLRProfileReportChoices();
          if (!harePCLRProfileReportChoices) return;
          pdf = await $generatePdf.generateHarePCLRProfileReport(
            client,
            evaluation,
            tool,
            harePCLRProfileReportChoices
          );
          break;
        case 157:
          // LSI-R
          const lsirProfileReportChoices =
            await $modals.evaluation.lsirProfileReportChoices();
          if (!lsirProfileReportChoices) return;
          pdf = await $generatePdf.generateLSIRProfileReport(
            client,
            evaluation,
            tool,
            lsirProfileReportChoices
          );
          break;
        case 188:
          // ACE Questionnaire
          const acesProfileReportChoices =
            await $modals.evaluation.acesProfileReportChoices();
          if (!acesProfileReportChoices) return;
          pdf = await $generatePdf.generateACESProfileReport(
            client,
            evaluation,
            tool,
            acesProfileReportChoices
          );
          break;
      }

      // let savedMediaRes = await Upload.upload(`/client-manager/${client.institutionId}/subgroups/${client.subGroup?.id}/clients/${client.id}/evaluations/${evaluation.id}/media`, {file: pdf});
      // console.log('savedMediaRes: ', savedMediaRes);
      // console.log('pdf generate response: ', pdf);
      let evalId = evaluation.id;
      if (!evalId && evaluation.evaluationId) evalId = evaluation.evaluationId;
      if (tool.id === 120 || tool.id === 124 || tool.id === 157) {
        //YLS/CMI or LS/CMI or LSI-R Used, so we can prompt them if they want to make case plan off of eval
        this.showPostReportNavOptions = true;
      }
      // loadReports(evalId);
    },
    async generateReport(evlConfig = this.evaluation) {
      // $loading = true;
      // console.log('generate report OLD evlConfig: ', evlConfig);
      $store.commit('setIsLoading', true);
      $store.commit('setLoadingMessage', 'Getting Report Information');
      if (!$store.state.evaluations.focus && evlConfig) {
        $store.commit('evaluations/setFocus', evlConfig.id);
      }
      // $store.commit('clients/SET_FOCUS', evlConfig.clientId);
      const client = $store.state.clients.focus;
      // this.evlForReport = $store.state.evaluations.focus;

      if (!evlConfig) {
        notify.display('No evaluation detected', 'error');
        $store.commit('setIsLoading', false);
        return;
      }
      $evl = {
        date: evlConfig.updatedAt,
        id: evlConfig.id,
        evaluation: {},
        report: {},
        reportTemplate: {},
        reportTemplates: [], // template options
        reportTemplateId: '',
        dictionary: []
      };

      let resEvl;

      try {
        // resEvl = await $api.evaluations.get(evlConfig.id);
        // resEvl = await getItems.evaluation(evlConfig.id, client);
        resEvl = await $store.dispatch('evaluations/get', {
          instId: client.institutionId,
          sbGrpId: client.subGroup?.id,
          clntId: client.id,
          evalId: evlConfig.id
        });
      } catch (err) {
        // $loading = false;
        $store.commit('setIsLoading', false);
        notify.display(
          `Cannot find Tool Commit Id. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`,
          'error'
        );
      }

      const evlEvl = resEvl,
        evlData = resEvl.evaluationData;
      // this.evlForReport = resEvl.data;

      // $store.commit('clients/SET_FOCUS', evlEvl.clientId);
      // let client = $store.state.clients.focus;

      let toolCommitId;

      if (evlData.toolCommitId) {
        toolCommitId = evlData.toolCommitId;
      } else if (evlEvl.toolCommitId) {
        toolCommitId = evlEvl.toolCommitId;
      }

      if (!toolCommitId) {
        notify.error(
          `Cannot find Tool Commit Id. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`
        );
        // $loading = false;
        $store.commit('setIsLoading', false);
        return;
      }

      // GET Tool Commit
      let resToolCmt;

      try {
        resToolCmt = await $api.toolCreator.getToolCommit({
          toolId: evlEvl.toolUsed,
          commitId: toolCommitId
        });

        if (resToolCmt.data.error) {
          $store.commit('setIsLoading', false);
          throw resToolCmt.data.error;
        }
      } catch (err) {
        // $loading = false;
        $store.commit('setIsLoading', false);
        notify.display('Error Grabbing Tool File for Report', 'error');
      }

      let codingFormId;
      if (resToolCmt.data?.codingForms?.length) {
        codingFormId = resToolCmt.data.codingForms[0].id;
      }
      const toolCmt = resToolCmt.data.toolData;

      // $evl.tool = await loadTool.load(toolCmt, client);
      $evl.tool = await $store.dispatch('tools/getToolData', {
        toolId: evlEvl.toolUsed,
        commitId: toolCommitId
      });

      const dictionary = toolCmt;

      if (dictionary.dictionary) {
        $evl.dictionary = dictionary.dictionary;
      } else {
        Reflect.deleteProperty($evl, 'dictionary');
      }

      if (evlData.data) {
        // eval from the SITE
        $evl.evaluation = evlData;
      } else {
        // eval from the APP
        const toolName = (
          _tools.find((tool) => evlEvl.toolUsed == tool.id) || {}
        ).name;
        const elapsedTime =
          typeof evlEvl.elapsedTime !== 'undefined' ? evlEvl.elapsedTime : null;
        const client = _clients.find(
          (client) => evlEvl.client.id == client.id || {}
        );
        let clientName = client ? client.name() : null;
        if (evlEvl.client.mName && !clientName) {
          clientName = `${evlEvl.client.fName} ${evlEvl.client.mName} ${evlEvl.client.lName}`;
        } else if (
          (evlEvl.client.fName || evlEvl.client.lName) &&
          !clientName
        ) {
          clientName = `${evlEvl.client.fName} ${evlEvl.client.lName}`;
        } else if (!clientName) {
          clientName = evlEvl.client.localId;
        }
        const evaluatorName = `${evlEvl.evaluator.lName}, ${evlEvl.evaluator.fName}`;

        $evl.evaluation = {
          clientId: evlEvl.clientId.toString(),
          clientName,
          data: resEvl.evaluationData,
          evaluationId: evlEvl.id,
          evaluatorId: evlEvl.evaluatorId,
          evaluatorName,
          elapsedTime,
          generalNotes: '',
          institutionId: evlEvl.institutionId,
          riskCategory: '',
          score: evlEvl.score,
          toolName: toolName ? toolName : $evl.tool.name,
          toolUsed: evlEvl.toolUsed,
          toolVersion: '',
          offenderHistoryId: resEvl.offenderHistoryId
        };
      }

      // GET Tool Templates
      const resTemps = await $api.toolCreator.listToolReportTemplates(
        evlEvl.toolUsed
      );
      let temp;
      if (resTemps.status === 200) temp = resTemps.data[0];

      if (!temp) {
        // if no template is found, throw us over to generateProfileReport
        // $loading = false;
        $store.commit('setIsLoading', false);
        if (resEvl.hasOwnProperty('evaluationData'))
          resEvl.evaluation = resEvl.evaluationData;
        this.generateProfileReport($evl.tool, resEvl, client);
        return;
      }

      // GET Template Commits
      let resTempCmts = await $api.toolCreator.listToolReportTemplateCommits({
        reportTemplateId: temp.id,
        toolId: evlEvl.toolUsed
      });
      if (resTempCmts.status === 200) resTempCmts = resTempCmts.data;
      const tempCmts = Array.isArray(resTempCmts)
        ? resTempCmts.filter(
            (comm) =>
              !!comm.toolCommits[0] && comm.toolCommits[0].id === toolCommitId
          )
        : null;

      const tempCmt =
        tempCmts.find((cmt) => cmt.status == 'Live') ||
        _.maxBy(tempCmts, 'version') ||
        _.maxBy(tempCmts, 'createdAt');

      if (!tempCmt) console.error('No live report template commit found');
      const resTcf = await $http.get(
        `/api/tool-creator/tools/${evlEvl.toolUsed}/report-templates/${temp.id}/commits/${tempCmt.id}?templateDataOnly=true`
      );
      const tcf = resTcf?.data?.templateData;

      tcf.version = tempCmt.version;

      $evl.reportTemplates.push({
        type: 'Base Template',
        template: tcf,
        templateId: tcf.id
      });

      $evl.report = {
        clientName: `${evlEvl.client.lName}, ${evlEvl.client.fName} ${evlEvl.client.mName}`,
        clientId: evlEvl.client.id,
        evaluatorName: evlEvl.evaluator.fullName,
        evaluatorId: evlEvl.evaluatorId,
        institutionName: '',
        institutionId: evlEvl.client.institutionId,
        toolName: $evl.evaluation.toolName,
        toolId: $evl.tool.id,
        version: $evl.evaluation.toolVersion,
        score: evlEvl.score,
        reportSections: {},
        codingFormId: codingFormId ? codingFormId : $evl.tool.codingFormId
      };

      if (!$evl.evaluation.score) $evl.evaluation.score = evlEvl.score;
      $store.commit('setIsLoading', false);
      $modals.evaluation.report($evl, (report) => {
        // console.log('report: ', report);
        report !== 'noEvalDataFound'
          ? reportUtils.openReports(null, true, report)
          : null;
        // prevCtrl.openEmailReportModal(report);
        // prevCtrl.reloadReports();
        // prevCtrl.changeSection('reports');
      });
      // $loading = false;
    },
    async openReports(row, noRow, report, referrer) {
      if (!noRow) {
        this.evaluation = row;
        row.loadingEvaluation = true;
      } else if (report) {
        notify.display(
          '<span class="glyphicon glyphicon-refresh spin-constant"></span> Loading Reports...',
          'primary'
        );
      }

      if (!!row && (!$client || $client === '' || $client === {})) {
        $client = _clients.find(
          (item) => row.clientId === item.id || row.id === item.id
        );
      }

      if (!$evl) {
        $evl = {
          id: this.evaluation.id
        };
      }

      try {
        await loadReports($evl.id);

        if (!noRow) {
          row.loadingEvaluation = false;
        } else if (report) {
          // coming from gears service
          Notification.clearAll();
          reportUtils.openEmailReportModal(report);
        }
      } catch (err) {
        $store.commit('reports/setError', true);

        errorHandler(err);
      } finally {
        $state.go('dashboardReports');
      }
    },
    async reloadReports() {
      if (!$evl.id) {
        return;
      }

      try {
        await loadReports(this.evaluation.id);

        // if (!!res.data.error) {
        //   throw res.data.error;
        // }
      } catch (err) {
        $store.commit('reports/setError', true);

        errorHandler(err);
      }

      $state.go('dashboardReports');
      // this.changeSection('reports');
    },
    async getReport(row) {
      $store.commit('setIsLoading', true);
      $store.commit('setLoadingMessage', 'Downloading Report...');
      const isMedia = row.bucket && row.path;
      // row.downloading = true;
      // row.status = 'Processing...';
      const client = $store.state.clients.focus;
      try {
        let res;
        if (isMedia) {
          // evaluation media
          const url = `api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${row.evaluationId}/media/${row.id}`;

          const existingHeaders = $http.defaults.headers.common;

          const newHeaders = {
            Accept: 'application/pdf'
          };

          const headers = Object.assign({}, existingHeaders, newHeaders);

          const config = {
            responseType: 'arraybuffer',
            headers: headers
          };

          const mimeType = 'application/pdf';
          const res = await $http.get(url, config);

          var blob = new Blob([res.data], { type: mimeType });
          const fileName = `${row.name}`
          download(blob, `${fileName}`, mimeType);

          // res = await $api.clientManager.getEvaluationMedia({
          //   mediaId: row.id,
          //   evalId: row.evaluationId,
          //   instId: client.institutionId,
          //   sbGrpId: client.subGroup.id,
          //   clntId: client.id
          // });
        } else {
          // evaluation report
          // res = await $api.clientManager.getReportDocument({
          //   reportId: row.id,
          //   evalId: row.evaluationId,
          //   instId: client.institutionId,
          //   sbGrpId: client.subGroup.id,
          //   clntId: client.id
          // });
          const url = `api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${row.evaluationId}/reports/${row.id}/doc`;

          const existingHeaders = $http.defaults.headers.common;

          const newHeaders = {
            Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          };

          const headers = Object.assign({}, existingHeaders, newHeaders);

          const config = {
            responseType: 'arraybuffer',
            headers: headers
          };

          const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          const res = await $http.get(url, config);

          var blob = new Blob([res.data], { type: mimeType });
          const fileName = `gears_report_${row.reportData.toolName}_${client.lName}_${format(new Date(), 'yyyy-MM-dd')}.docx`
          download(blob, `${fileName}`, mimeType);
        }

        row.status = 'Downloading...';
        row.downloading = false;

        $emailingReport = false;

        if (res.status !== 200) {
          errorHandler(res);
          $store.commit('setIsLoading', false);
          return;
        }

        const session = await $auth.getSession();
        let filename;
        let url;
        let contentType;
        const x = new XMLHttpRequest();
        if (isMedia) {
          url = `${$location.$$absUrl.replace(
            $location.$$path,
            ''
          )}/api/client-manager/${client.institutionId}/subgroups/${
            client.subGroup.id
          }/clients/${client.id}/evaluations/${row.evaluationId}/media/${
            row.id
          }`;

          filename = row?.name
            ? row.name
            : `${format(new Date(), 'MM-dd-yyyy')}_${row.id}_Report.pdf`;
          contentType = 'application/pdf';
        } else {
          url = `${$location.$$absUrl.replace(
            $location.$$path,
            ''
          )}/api/client-manager/${client.institutionId}/subgroups/${
            client.subGroup.id
          }/clients/${client.id}/evaluations/${row.evaluationId}/reports/${
            row.id
          }/doc`;

          filename = `${format(new Date(), 'MM-dd-yyyy')}_${
            row.reportData?.toolName
          }_Report.docx`;
          if (res.headers['content-disposition']) {
            if (res.headers['content-disposition'].search('filename=') > 0) {
              filename = res.headers['content-disposition'].substr(
                res.headers['content-disposition'].search('filename=') + 9,
                res.headers['content-disposition'].length - 1
              );
            }
          }
          contentType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }

        x.open('GET', url, true);
        x.setRequestHeader('Content-type', contentType);
        x.setRequestHeader(
          'Authorization',
          'Bearer ' + session.accessToken.jwtToken
        );
        x.responseType = 'blob';
        x.onload = function (e) {
          download(x.response, filename, contentType);
          $store.commit('setIsLoading', false);
        };
        x.send();
      } catch (err) {
        $store.commit('setIsLoading', false);
        errorHandler(err);
      }
    },
    openEmailReportModal(id) {
      if (typeof id !== 'string' && id.id) id = id.id;
      $modals.evaluation.emailReport(angular.copy(_users), id, (report) => {
        reportUtils.emailReport(report);
      });
    },
    async emailReport(emailList) {
      $store.commit('setIsLoading', true);
      $store.commit('setLoadingMessage', 'Emailing Report...');
      $emailingReport = true;
      const client = $store.state.clients.focus;
      const evaluation = $store.state.evaluations.focus;

      const payload = {
        users: emailList.users || null,
        emails: emailList.emails || null,
        fileType: 'docx'
      };

      $store.commit('setLoadingMessage', 'Sending Emails with Report...');

      try {
        // let res = await $api.reports.emailRequest(emailList.reportId, payload);
        const res = await $api.clientManager.emailReport(
          {
            reportId: emailList.reportId,
            instId: client.institution?.id,
            sbGrpId: client.subGroup?.id,
            clntId: client.id,
            evalId: evaluation.id
          },
          payload
        );

        $emailingReport = false;

        if (res?.data?.error) {
          throw res.data.error;
        }
        notify.display('Report Emailed Successfully', 'success');
      } catch (err) {
        notify.display(err, 'error');
        errorHandler(err);
      } finally {
        $store.commit('setIsLoading', false);
      }
    }

    // endregion Reports Functions
  };

  return reportUtils;
}
