import angular from 'angular';

import { INotifyService } from '@services/notify';
import * as Sentry from '@sentry/browser';

export interface ErrorHandlerService {
  /**
   * ...
   *
   * @param err ...
   * @param noNotify ...
   */
  (err: any, noNotify?: boolean): void;
}

/**
 * ...
 */
export type IErrorHandlerService = ErrorHandlerService;

declare module 'angular' {
  namespace gears {
    type IErrorHandlerService = ErrorHandlerService;
  }
}

/**
 * ...
 */
const ERRORS = {
  ServerError: {
    title: 'Internal Server Error',
    message: `Something went wrong on our end. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`
  },
  NotFound: {
    title: 'Not Found',
    message: `The entity or place you were looking for was not found. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`
  },
  Forbidden: {
    title: 'Forbidden',
    message: `Entity is forbidden from being accessed. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`
  },
  Unauthorized: {
    title: 'Unauthorized',
    message: `You are not authorized to access this entity. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`
  }
};

/**
 * ...
 *
 * @param err ...
 * @return
 */
function generateErrorMessage(err: any) {
  let status: unknown = null;

  if ('status' in err) {
    status = err.status;
  } else if ('response' in err) {
    status = err.response.status;
  }

  if (status === 500) return ERRORS.ServerError;
  if (status === 404) return ERRORS.NotFound;
  if (status === 403) return ERRORS.Forbidden;
  if (status === 401) return ERRORS.Unauthorized;

  if (!err.data || !err.data.feedback) {
    return {
      title: 'Unknown Error',
      message: `An unknown error occured while processing your request. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`
    };
  }

  if (err.data.feedback.message) {
    return { title: 'Error', message: err.data.feedback.message };
  }

  const message = JSON.parse(err.data.feedback);

  return {
    title: 'Error',
    message: message.message || err.data.feedback
  };
}

function errorHandlerService(notify: INotifyService) {
  'ngInject';

  return (err: any, noNotify = false) => {
    console.log('error in error handler: ', err);

    const errorConfig = generateErrorMessage(err);
    Sentry.captureException(err);

    if (!noNotify) {
      notify.error({ ...errorConfig, delay: true });
    }
  };
}

export default angular
  .module('app.errorHandler', [])
  .service('errorHandler', errorHandlerService).name;
