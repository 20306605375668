export const environment = {
  apiUrl: 'https://dev.gifrinc.com',
  client_id: 'a440842d-d747-416f-b2bb-6297cb2253f8',
  scope: 'a440842d-d747-416f-b2bb-6297cb2253f8/custom.all',
  redirect_uri: 'https://dev.gifrinc.com/auth-redirect',
  code_verifier: 'MLWHTqbX7V3AjTaSmlJJsIEtu2Y_FKdeaj9oA2cS8RIDqzY4ziMWbhjTrvfUMsWc',
  entra_tenant_id: 'c1ee452c-c67d-44ec-95fa-a4120f620e44',
  entra_tenant_name: 'devgears',
  authURL: 'https://devgears.ciamlogin.com/devgears.onmicrosoft.com/oauth2/v2.0/authorize?client_id=a440842d-d747-416f-b2bb-6297cb2253f8&nonce=P5ys6zF-l7&redirect_uri=https://dev.gifrinc.com/auth-redirect&scope=openid%20a440842d-d747-416f-b2bb-6297cb2253f8/custom.all&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=jpuKEDbjgIaczr4zQh8OYZMVMI0nnlaiyKbSvFyrKBo',
  sentryDSN: 'https://o1281049.ingest.us.sentry.io/4507691469701120'
};
