import axios from 'axios';

import { logger } from './logger';
import * as Sentry from '@sentry/browser';
interface MakeRequestOptions {
  method: 'get' | 'post' | 'put' | 'del';
  url: string;
  data?: any;
}

interface RequestErrorConstructorOptions {
  message?: string;
  code?: string;
}

export class RequestError extends Error {
  code: string | null;

  constructor(options: string | RequestErrorConstructorOptions) {
    let message: string | null = null;
    let code: string | null = null;

    if (typeof options === 'string') {
      message = options;
    } else if (options && typeof options === 'object') {
      message = options.message || 'An unknown error occurred.';
      code = options.code || 'UNKNOWN_ERROR';
    }

    super(message as string);

    this.code = code;
  }
}

class Request {
  /**
   * Make a web request.
   *
   * @param config Request configuration object.
   * @return A `Promise` that will resolve to the request's response.
   */

  getHeaders() {
    const headers = {
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    };

    try {
      const accessToken = localStorage.getItem('TOKEN');
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
    } catch (_) {
      // There isn't a user session to retrieve a token from -- silently continue.
    }

    return headers;
  }

  async make(config: MakeRequestOptions) {
    let response: unknown = null;
    let error: unknown = null;

    const method = config.method === 'del' ? "delete": config.method;

    const options = {
      method: method.toUpperCase(),
      url: `${process.env.API_ORIGIN}/` + config.url,
      headers: this.getHeaders()
    };

    if (config.data) {
      options.data = config.data;
    }

    // const options = Object.assign(
    //   { response: true },
    //   config.data ? { body: config.data } : {}
    // );

    try {
      response = await axios.request(options as any);
    } catch (err) {
      Sentry.captureException(err);
      error = err;
    }
    console.log('🚀 ~ Request ~ make ~ error:', error);

    response = response || error?.response || null;

    logger(response);

    let errorData: RequestErrorConstructorOptions | null = null;

    if (error) {
      errorData = error.response?.data || error;
    } else {
      errorData = response?.data?.error;
    }

    console.log('🚀 ~ Request ~ make ~ errorData:', errorData);
    if (errorData) {
      Sentry.captureException(new RequestError(errorData));
      throw new RequestError(errorData);
    }

    return response?.data?.data || response?.data || response;
  }

  /**
   * Make a `GET` request.
   *
   * @param url URL to use for request.
   * @return Promise that will resolve to the data, if any, received in the`
   * response.
   */
  get(url: string) {
    return this.make({ method: 'get', url });
  }

  /**
   * Make a `POST` request.
   *
   * @param url URL to use for request.
   * @param data Request data.
   * @return Promise that will resolve to the data, if any, received in the`
   * response.
   */
  post(url: string, data?: unknown) {
    return this.make({ method: 'post', url, data });
  }

  /**
   * Make a `PUT` request.
   *
   * @param url URL to use for request.
   * @param data Request data.
   * @return Promise that will resolve to the data, if any, received in the`
   * response.
   */
  put(url: string, data?: unknown) {
    return this.make({ method: 'put', url, data });
  }

  /**
   * Make a `DELETE` request.
   *
   * @param url URL to use for request.
   * @return Promise that will resolve to the data, if any, received in the`
   * response.
   */
  delete(url: string, config?: any) {
    return this.make({ method: 'del', url });
  }
}

export const request = new Request();
