'use strict';

import shortid from 'shortid';
import { isValid, format } from 'date-fns';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

type NewUser = {
  username: string,
  fName: string,
  lName: string,
  dob: Date | null,
  phoneCell: string,
  phoneWork: string,
  address1: string,
  address2: string,
  address3: string,
  city: string,
  stateProvince: string,
  postalCode: string,
  country: string
};

type UserAddress = {
  address1: string,
  address2: string,
  address3: string,
  city: string,
  stateProvince: string,
  postalCode: string
};

@Controller
class RegisterView {
  submitted: boolean = false;
  countries: {}[] = [];
  inviteId: string = '';
  passwordConfirm: string = '';
  passwordPattern: Regex;
  phonePattern: Regex = '';
  user: NewUser = {
    username: '',
    fName: '',
    lName: '',
    dob: null,
    // password: '',
    email: '',
    phoneWork: '',
    phoneCell: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    country: ''
  };
  formShow: UserAddress = {
    address1: false,
    address2: false,
    address3: false,
    city: false,
    stateProvince: false,
    postalCode: false
  };
  errors: {} = {};

  @Inject $state;
  @Inject $stateParams;
  @Inject $location;
  @Inject $api;
  @Inject $auth;
  @Inject $http;
  @Inject $modals;
  @Inject utils;
  @Inject getFormItems;
  @Inject notify;

  async $onInit() {
    if (this.$auth.loggedIn) {
      this.utils.notify('warning', {
        message: 'You must be logged out to register'
      });

      this.$state.go('main');

      return;
    }
    //TODO (Alex) make a more robust phone regex that handles various countries
    this.phonePattern =
      /^([a-zA-Z,#/ \.\(\)\-\+\*]*[0-9]){7}[0-9a-zA-Z,#/ \.\(\)\-\+\*]*$/;
    this.passwordPattern =
      /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/;

    let inviteId = this.$location.search().id;

    // Check if there is an id in the URL
    if (!inviteId || inviteId === '') {
      this.utils.notify('warning', {
        message: 'You must be invited to register',
        title: 'No Invitation'
      });

      this.$state.go('main');

      return;
    }

    // Get invitation id
    this.user.inviteId = inviteId;

    // Check invitation id
    try {
      let res = await this.$http.get(`/api/invites/${inviteId}`);
      if (res.status !== 204) {
        this.notify.display('Invalid invitation id', 'error');
        return;
      }
      this.user.username = shortid.generate();
    } catch (err) {
      this.utils.notify('warning', {
        message: 'You must be invited to register',
        title: 'No Invitation'
      });

      this.$state.go('main');

      return;
    }

    // Get countries for address form
    this.getFormItems.countries().then((res) => {
      this.countries = res;
    });
  }

  async openTOS() {
    let agreed = await this.$modals.settings.termsAndConditions();

    this.agree(agreed);
  }

  //Submit registration form
  async register(form) {
    this.submitted = true;

    if (!form.$valid) {
      this.submitted = false;
      return;
    }

    if (!this.user.tosAgree) {
      this.utils.notify('warning', {
        title: 'Agree to the Terms & Conditions',
        message:
          'You must read through and agree to the Terms & Conditions to register.'
      });
      this.submitted = false;
      return;
    }

    let dob = null;

    if (this.user.dob) {
      // let dob = angular.copy(format(new Date(this.user.dob), 'MM/dd/yyyy'));
      dob = format(new Date(this.user.dob), 'MM/dd/yyyy');

      if (!isValid(new Date(dob))) {
        this.utils.notify('warning', {
          title: 'Date of Birth Format',
          message: 'Invalid Date Format'
        });
        this.submitted = false;
        return;
      }
    }

    try {
      let res = await this.$api.user.createUser({
        username: this.user.username,
        fName: this.user.fName,
        lName: this.user.lName,
        dob,
        email: this.user.email,
        phoneWork: this.user.phoneWork,
        phoneCell: this.user.phoneCell,
        address1: this.user.address1,
        address2: this.user.address2,
        address3: this.user.address3,
        city: this.user.city,
        state: this.user.stateProvince,
        postalCode: this.user.postalCode,
        country: this.user.country,
        inviteId: this.user.inviteId,
        institutionId: this.user.institutionId
      });
    } catch (err) {
      this.notify.error(err);
      return;
    } finally {
      this.submitted = false;
    }

    this.notify.display(
      'You will receive an email shortly with your temporary password for login',
      'success',
      true,
      'Registration Successful'
    );

    this.submitted = false;

    this.$state.go('main');
  }

  // Update address field on country choice
  updateAddressFields() {
    if (this.user.country.toString() === 'US') {
      this.formShow.address1 =
        this.formShow.address2 =
        this.formShow.address3 =
        this.formShow.city =
        this.formShow.stateProvince =
        this.formShow.postalCode =
          true;
      this.getFormItems.usStates().then((response) => {
        this.states = response;
      });
    } else if (this.user.country.toString() === 'CA') {
      this.formShow.address1 =
        this.formShow.address2 =
        this.formShow.address3 =
        this.formShow.city =
        this.formShow.stateProvince =
        this.formShow.postalCode =
          true;
      this.getFormItems.canadaProvinces().then((response) => {
        this.states = response;
      });
    } else if (this.user.country.toString() === 'GB') {
      this.formShow.address1 =
        this.formShow.address2 =
        this.formShow.address3 =
        this.formShow.city =
        this.formShow.stateProvince =
        this.formShow.postalCode =
          true;
      this.getFormItems.ukProvinces().then((response) => {
        this.states = response;
      });
    } else if (this.user.country.toString() === 'AU') {
      this.formShow.address1 =
        this.formShow.address2 =
        this.formShow.address3 =
        this.formShow.city =
        this.formShow.stateProvince =
        this.formShow.postalCode =
          true;
      this.getFormItems.auStates().then((response) => {
        this.states = response;
      });
    } else if (this.user.country) {
      this.formShow.address1 =
        this.formShow.address2 =
        this.formShow.address3 =
        this.formShow.city =
        this.formShow.stateProvince =
        this.formShow.postalCode =
          true;
      this.states = [];
    }
  }

  agree(x) {
    if (x) this.user.tosAgree = true;
  }
}

export default RegisterView;
