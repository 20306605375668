import angular from 'angular';
import { find, findKey, some } from 'lodash';

import { HasAccessCheckOptions } from '@services/acl/acl.service';
import axios from 'axios';
import qs from 'qs';
import { environment } from '@root/environments/';

async function authEntra(options: LoginOptions) {
  const data = qs.stringify({
    grant_type: 'authorization_code',
    client_id: environment.client_id,
    scope: environment.scope,
    code: options,
    redirect_uri: environment.redirect_uri,
    code_verifier: environment.code_verifier
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://${environment.entra_tenant_name}.ciamlogin.com/${environment.entra_tenant_id}/oauth2/v2.0/token`,
    headers: {
      Cookie: 'x-ms-cpim-geo=NA',
      'Access-Control-Allow-Origin': '*',
      Origin: environment.redirect_uri,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };

  try {
    const response = await axios.request(config);
    const dat = response.data;
    console.log(dat);

    const token = dat.access_token;
    const session = JSON.stringify(dat);

    localStorage.setItem('TOKEN', token);
    localStorage.setItem('SESSION', session);

    return dat;
  } catch (error) {
    console.error('Error during login request:', error);
    throw error;
  }
}

function logoutTODO() {
  localStorage.removeItem('TOKEN');
  localStorage.removeItem('SESSION');
}

function getCurrentSessionTODO() {
  try {
    const sessionStr = localStorage.getItem('SESSION');
    if (!sessionStr) {
      return null;
    }
    const session = JSON.parse(sessionStr);
    return session;
  } catch (e) {
    return null;
  }
}

/**
 * ...
 */
export interface LoginOptions {
  code: string;
}

/**
 * ...
 */
export class AuthService {
  #loggedIn = false;
  #authenticating = false;
  #activeInstId: string | null = null;
  #sessionChecked = false;
  #timeSinceLastSessionTag = -1;
  #mfaChoice: string = '';
  #mfaCellPhone: string = '';
  #isLoginAuthEntra = false;
  // #forceLogout = null;
  // #permissions = null;

  get isLoginAuthEntra() {
    return this.#isLoginAuthEntra;
  }

  /**
   * ...
   */
  get loggedIn() {
    return this.#loggedIn;
  }

  /**
   * ...
   */
  get loggedInAsync() {
    return new Promise<boolean>((resolve) => {
      void this.getSession().then((session) => {
        resolve(!!session);
      });
    });
  }

  /**
   * ...
   */
  get user() {
    return this.$store.state.me;
  }

  /**
   * ...
   */
  get institutionId() {
    return this.#activeInstId;
  }

  /**
   * ...
   */
  get mfaChoice() {
    return this.#mfaChoice;
  }

  /**
   * ...
   */
  set mfaChoice(choice) {
    this.#mfaChoice = choice;
  }

  /**
   * ...
   */
  get mfaCellPhone() {
    return this.#mfaCellPhone;
  }

  /**
   * ...
   */
  set mfaCellPhone(number) {
    this.#mfaCellPhone = number;
  }

  constructor(
    private readonly $rootScope: angular.IRootScopeService,
    private readonly $http: angular.IHttpService,
    private readonly $uibModalStack: angular.ui.bootstrap.IModalStackService,
    private readonly Notification: angular.uiNotification.INotificationService,
    private readonly $store: angular.gears.IStoreService,
    private readonly $api: angular.gears.IApiService,
    private readonly $api2: angular.gears.IAPI2Service,
    private readonly $ls: angular.gears.ILsService,
    private readonly $acl: angular.gears.IAclService,
    private readonly $modals: angular.gears.IModalsService,
    private readonly notify: angular.gears.INotifyService,
    private readonly aggregateReportsMonitor: unknown,
    private readonly aggregateUserReportsMonitor: unknown,
    private readonly activityReportsMonitor: unknown
  ) {
    'ngInject';

    const lastLoadedCache = localStorage.getItem('lastLoaded');
    const dateTime = lastLoadedCache ? new Date(lastLoadedCache) : null;

    // Get time differnce between vurrent datetime and the last session tag.
    this.#timeSinceLastSessionTag = !dateTime
      ? -1
      : 0.001 * (Date.now() - dateTime.getTime());

    // console.log('timeSinceLastSessionTag', this.#timeSinceLastSessionTag);
  }

  async currentAuthenticatedUser() {
    // TODO
    const user = await this.getCurrentUser();

    user['attributes'] = {};
    return user;
  }

  async getPreferredMFA() {
    // TODO
    const method = await this.$http.get('/api/users/2fa');
    const data = method?.data?.value as any[];
    const config = {
      '3179e48a-750b-4051-897c-87b9720928f7': 'SMS_MFA',
      '3ddfcfc8-9383-446f-83cc-3ab9be4be18f': 'EMAIL'
    };
    const foundItem = find(data, (item) =>
      some(config, (value, key) => item.id.includes(key))
    );

    if (foundItem) {
      const matchingKey = findKey(config, (value, key) =>
        foundItem.id.includes(key)
      );
      if (matchingKey === '3179e48a-750b-4051-897c-87b9720928f7') {
        this.#mfaCellPhone = foundItem.phoneNumber;
      }
      return config[matchingKey];
    }

    return 'NOMFA';
  }

  /**
   * ...
   *
   * @return
   */
  async getSession() {
    let session: any | null = null;

    if (this.#authenticating) {
      return new Promise((resolve) => {
        this.$rootScope.$once('authComplete', (_: Event, session: any) =>
          resolve(session)
        );
      });
    }

    this.#authenticating = true;

    const completeFetch = (session: any | null) => {
      // console.log('LOGGED IN:', this.#loggedIn);
      console.log('completeFetch', session);
      this.#sessionChecked = true;
      this.#authenticating = false;
      this.$rootScope.$broadcast('authComplete', session);

      return session;
    };

    session = getCurrentSessionTODO();
    this.#loggedIn = !!session;

    if (!this.#loggedIn) {
      return completeFetch(null);
    }

    if (!this.#sessionChecked) {
      this.#sessionChecked = true;

      if (
        this.#timeSinceLastSessionTag === -1 ||
        this.#timeSinceLastSessionTag > 10
      ) {
        await this.logout();

        this.Notification.warning(
          'You were automatically logged out of your session due to being idle.'
        );

        return completeFetch(null);
      }
    }

    if (this.user.id) {
      return completeFetch(session);
    }
    let currentAuthUser;
    try {
      currentAuthUser = await this.currentAuthenticatedUser();
    } catch (error) {
      console.log(error);
      this.logout();
    }
    // TODO
    this.#mfaChoice = await this.getPreferredMFA();

    if (
      currentAuthUser.attributes['custom:mfa'] === 'EMAIL' &&
      this.#mfaChoice === 'NOMFA'
    ) {
      this.#mfaChoice = 'EMAIL';
    }

    const me = await this.$store.dispatch('me/get');

    this.$store.commit('permissions/SET', me.policies);

    // Fetch active institution.
    let activeInstId = this.$ls.get(`${me.id}:activeInstitutionId`);

    // check to make sure if we have activeInstId that it's in their policies array
    if (
      activeInstId &&
      activeInstId !== '*' &&
      !find(me.policies, { institutionId: activeInstId })
    ) {
      activeInstId = null;
      this.$ls.set(`${me.id}:activeInstitutionId`, null);
    }

    let activeInst: unknown = null;

    if (activeInstId === null || activeInstId === undefined) {
      activeInst = await this.$modals.settings.chooseActiveInstitution({
        dismissable: false
      });

      console.log(activeInst);

      if (!activeInst) return;

      if (activeInst === 'noInstitution') activeInst = null;
      if (activeInst)
        activeInst = await this.$store.dispatch(
          'institutions/get',
          activeInst?.id
        );

      this.$ls.set(
        `${me.id}:activeInstitutionId`,
        activeInst ? activeInst.id : '*'
      );

      activeInstId = activeInst?.id;
    } else if (activeInstId !== '*') {
      activeInst = await this.$store.dispatch('institutions/get', activeInstId);
    }

    this.$store.commit('me/SET_PROPS', { institution: activeInst });
    this.$store.commit('permissions/SET_ACTIVE', activeInstId);

    this.setInstitutionConfigs(activeInst);

    // setPermited();

    return completeFetch(session);
  }

  /**
   *
   */
  setInstitutionConfigs(inst: any) {
    // If we are not a GEARS admin and the institution has clientConfig
    // set it for user over the site
    if (inst?.clientConfig) {
      this.$store.commit(
        'clients/setProps',
        { clientConfig: inst.clientConfig },
        true
      );
    }

    // If we are not a GEARS admin and the institution has evaluationConfigs
    // set them for use over the site
    if (inst?.evaluationConfigs?.length) {
      this.$store.commit(
        'tools/setProps',
        { evaluationConfigs: inst.evaluationConfigs },
        true
      );
    }
  }

  /**
   * ...
   *
   * @return
   */
  async setActiveInstitution() {
    const choice = await this.$modals.settings.chooseActiveInstitution();

    if (!choice) return;

    this.$store.commit('setLoadingMessage', 'Loading...');
    this.$store.commit('setIsLoading', true);
    // Clearing store states for new institution
    this.$store.commit('activityReports/CLEAR');
    this.$store.commit('aggregateReports/CLEAR');
    this.$store.commit('aggregateUserReports/CLEAR');
    this.$store.commit('aggregateUserReports/CLEAR');
    this.$store.commit('analytics/CLEAR');
    this.$store.commit('clientRequests/CLEAR');
    this.$store.commit('clientTransfers/CLEAR');
    this.$store.commit('clients/CLEAR');
    this.$store.commit('evaluationRequests/CLEAR');
    this.$store.commit('evaluations/CLEAR');
    this.$store.commit('institutionTransfers/CLEAR');
    this.$store.commit('institutions/CLEAR');
    this.$store.commit('invitations/CLEAR');
    this.$store.commit('locations/CLEAR');
    this.$store.commit('logs/CLEAR');
    this.$store.commit('offenseClassifications/CLEAR');
    this.$store.commit('policies/CLEAR');
    this.$store.commit('reports/CLEAR');
    this.$store.commit('tools/CLEAR');
    this.$store.commit('users/CLEAR');

    const institution = choice === 'noInstitution' ? null : choice;

    this.#activeInstId = institution ? institution.id : '*';

    // if (typeof this.user.id === 'number') {
    this.$ls.set(`${this.user.id}:activeInstitutionId`, this.#activeInstId);
    // }

    if (this.#activeInstId !== '*') {
      const inst = await this.$store.dispatch(
        'institutions/get',
        this.#activeInstId
      );
      this.setInstitutionConfigs(inst);
    }

    this.$store.commit('me/SET_PROPS', { institution });
    this.$store.commit('permissions/SET_ACTIVE', this.#activeInstId);
    this.$store.commit('setIsLoading', false);
  }

  async getCurrentUser() {
    const user = await this.$api2.user.getMe();
    return user;
  }

  /**
   * ...
   *
   * @param options ...
   * @return
   */
  async login(options: LoginOptions) {
    // possible amplify interferance for network error
    // if (event) event.preventDefault();
    const { code } = options;
    let user = null;
    let error = null;
    this.#isLoginAuthEntra = true;
    try {
      await authEntra(options);

      user = await this.getCurrentUser();
    } catch (err) {
      error = err;
      this.#isLoginAuthEntra = false;

      window.localStorage.clear();
      this.notify.display(
        'There was a temporary issue with logging in. Please try again [Quota]',
        'warning'
      );
      throw error;
    }

    if (error) {
      this.notify.display(error, 'error');
      this.#isLoginAuthEntra = false;

      throw error;
    }

    if (!user) {
      this.#isLoginAuthEntra = false;
      throw 'User not retrieved';
    }

    await this.getSession();
    const me = this.$store.state.me;

    this.$rootScope.$broadcast('loggedIn');

    // Hot fix for impropper sessioon loading in IE
    if (this.$store.state.isIE) {
      setTimeout(() => location.reload());
    }
    this.#isLoginAuthEntra = false;
    return me;
  }

  /**
   * ...
   *
   * @return
   */
  async logout() {
    this.$store.commit('SET_LOADING_MESSAGE', 'Logging out...');
    this.$store.commit('SET_IS_LOADING', true);

    try {
      await this.$api2.user.logout();
      logoutTODO();
    } catch (err) {
      console.error(err);
    }

    this.$store.commit('me/SET');
    this.$store.dispatch('clearStates');

    this.#loggedIn = false;

    // close any open modals
    this.Notification.clearAll();
    this.$uibModalStack.dismissAll();
    this.aggregateReportsMonitor.end();
    this.aggregateUserReportsMonitor.end();
    this.activityReportsMonitor.end();

    this.$rootScope.$broadcast('loggedOut');
    this.$store.commit('setIsLoading', false);
    //this.$state.go('main');
  }

  async logoutExpunge() {
    this.$store.commit('SET_LOADING_MESSAGE', 'Logging out...');
    this.$store.commit('SET_IS_LOADING', true);
    logoutTODO();

    this.$store.commit('me/SET');
    this.$store.dispatch('clearStates');

    this.#loggedIn = false;

    // close any open modals
    this.Notification.clearAll();
    this.$uibModalStack.dismissAll();
    this.aggregateReportsMonitor.end();
    this.aggregateUserReportsMonitor.end();
    this.activityReportsMonitor.end();

    this.$rootScope.$broadcast('loggedOut');
    this.$store.commit('setIsLoading', false);
  }

  /**
   * ...
   *
   * @return
   */
  async createUser(data: unknown) {
    // await Auth.signUp();
    const res = await this.$api2.user.createUser(data);
  }

  /**
   * ...
   *
   * @return
   */
  async changePassword(oldPassword: string, newPassword: string) {
    const res = await this.$api2.user.changePassword({
      oldPassword,
      newPassword
    });
  }

  /**
   * Invoke the `$acl` service using the currently active permission profile.
   *
   * @param checks Access check statement(s) to evaluate against the current
   * permission profile.
   * @return `true` if the check passes, otherwise `false`.
   */
  hasAccess(checks: HasAccessCheckOptions) {
    const { profile } = this.$store.state.permissions;

    if (!profile) {
      throw new Error(
        'Must have a valid permission profile set to make an access check.'
      );
    }

    return this.$acl(checks, profile);
  }

  /**
   * Takes an action string and returns the resources associated with the
   * associated statement.
   *
   * @param action ...
   * @return
   */
  getActionResources(action: string) {
    // first make sure we are allowed to make the call
    if (!$actionPrms[action]) {
      console.error(
        '[auth:getActionResources: User is not permitted that action]'
      );

      return;
    }

    const statementAction = find($$permissions.master.actions, (val, key) => {
      return key === action;
    });

    if (!statementAction) {
      console.error(
        '[auth:getActionResources: Could not find statement action in permissions.master.actions list]'
      );
      return;
    }

    return statementAction.resources;
  }

  /**
   * ...
   *
   * @param choice ...
   * @return
   */
  async setMFA(choice: 'NOMFA' | 'SMS' | 'EMAIL') {
    if (choice === 'NOMFA') {
      try {
        await this.$api2.user.twofa({ mfa: choice });
        this.notify.display('MFA Preference Updated to None', 'success');
      } catch (err) {
        this.notify.display(err, 'error');
        throw err;
      }
    } else if (choice === 'SMS') {
      try {
        await this.$api2.user.twofa({ mfa: 'SMS' });
        this.notify.display('MFA Preference Updated to SMS', 'success');
      } catch (err) {
        this.notify.display(err, 'error');
        throw err;
      }
    } else if (choice === 'EMAIL') {
      try {
        await this.$api2.user.twofa({ mfa: 'EMAIL' });
        this.notify.display('MFA Preference Updated to Email', 'success');
      } catch (err) {
        this.notify.display(err, 'error');
        throw err;
      }
    }

    return choice;
  }

  /**
   * ...
   *
   * @return
   */
}
