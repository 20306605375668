import axios from 'axios';
import * as Sentry from '@sentry/browser';
function $requestWrapper(utils, notify) {
  'ngInject';

  const styles = (style) =>
    Object.keys(style)
      .map((key) => `${key}: ${style[key]};`)
      .join('');

  const logArgs = (...args) => {
    let str = '';
    let colors = [];

    args.forEach((item) => {
      let color = '#e4e4e4';

      str += '%c';

      if (Array.isArray(item)) {
        str += item[0];

        if (item[1]) {
          color = item[1];
        }
      } else {
        str += item.toString();
      }

      let style = typeof color == 'object' ? color : { color };

      colors.push(styles(style));
    });

    return {
      str,
      colors
    };
  };

  const createLog = (...args) => {
    let { str, colors } = logArgs(...args);

    return () => console.log(str, ...colors);
  };

  const createLogGroup = (...args) => {
    let { str, colors } = logArgs(...args);

    console.groupCollapsed(str, ...colors);

    return () => console.groupEnd();
  };

  const getHeaders = () => {

    const headers = {
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    };

    try {
      const accessToken = localStorage.getItem('TOKEN');
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
    } catch (_) {
      // There isn't a user session to retrieve a token from -- silently continue.
    }

    return headers;

  }

  const logger = function logger(res, req) {
    if (typeof res != 'object') {
      console.log(res, req);

      return;
    }

    let wasSuccess = utils.matches(
      res.status,
      200,
      201,
      202,
      203,
      204,
      205,
      206,
      207,
      208,
      226
    );

    let status = wasSuccess ? 'Success' : 'Failed';

    const log = createLogGroup(
      [
        ' API ',
        {
          color: '#000000',
          background: '#E6DB74',
          'border-radius': '6px'
        }
      ],
      '  ',
      [
        wasSuccess ? ' ✔ ' : ' ✘ ',
        {
          color: '#000000',
          background: wasSuccess
            ? 'linear-gradient(#89d662, #12a757)'
            : 'linear-gradient(#ec6b39, #ff0047)',
          'font-size': '12px',
          'border-radius': '6px'
        }
      ],
      '  ',
      [req.type.toUpperCase(), '#ff8822'],
      [' --> ', '#6d6d6d'],
      [req.route, '#F92672']
    );

    let resObj = res.response || res;

    console.log('status', resObj.status);
    console.log('statusText', resObj.statusText);
    console.log('payload', req.body);

    let dataObj = resObj.data;
    if (
      dataObj &&
      typeof dataObj == 'object' &&
      dataObj.hasOwnProperty('data')
    ) {
      console.log('data', dataObj.data);
    } else {
      console.log('data', dataObj);
    }

    console.log('error', dataObj?.error);
    // console.log('feedback', dataObj.feedback);
    createLog('feedback - ', [dataObj?.feedback || '', '#E6DB74'])();
    console.log('fullResponce', res);

    log();
  };

  const $wrapper = async function $request(type, route, body) {
    let res,
      success = false;
    const url = `${process.env.API_ORIGIN}/` + route;
    const method = type === 'del' ? "delete" : type;
    const headers = getHeaders();
    const options = {
      url,
      method,
      headers,
      data: body
    }

    try {
      res = await axios.request(options);

      // if (!!res.error || (!!res.data && !!res.data.error)) {
      //   throw res.data.error
      // }

      success = true;
    } catch (err) {
      // suppress notification on this route
      if (!route.includes('clients/public/')) {
        notify.display(err.response, 'error');
      }
      res = err;
      Sentry.captureException(err);
    }

    if (window.location.hostname == 'localhost') {
      logger(res, { type, route, body });

      if (!success) {
        utils.notify('error', `The request to ${route} failed.`);
      } else {
        // utils.notify('success', `The request to ${route} was successfull.`);
      }
    }

    if (!success) {
      throw res;
    }

    return res;
  };

  return $wrapper;
}

export default $requestWrapper;
